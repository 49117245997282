
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options, prop } from "vue-class-component";
import { LocationController } from "../../controllers/LocationController";

class Props {
  modelValue = prop<any>({});
  placeholder = prop<string>({
    default: "Ketik atau pilih kota"
  });
  keyName = prop<string>({
    default: "name"
  });
  keyValue = prop<string>({
    default: "code"
  });
  disabled = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["update:modelValue", "change"]
})
export default class MultipleSelectCommodity extends Vue.with(Props) {
  fetchCity(value: string) {
    if (value.length > 2 || !value) {
      LocationController.getCityList({
        search: value,
        status: "",
        page: 1,
        limit: 10,
        freeTradeZone: "yes"
      });
    }
  }
  get cityData() {
    const tmp: Array<any> = [{ name: "ALL", code: "ALL" }];
    const city = LocationController.cityData.cityData;

    return [...tmp, ...city];
  }
  cityValue: Array<any> = [];
  get loading() {
    return LocationController.isLoading;
  }
  onBlur() {
    LocationController.setLoadingList(true);
  }
}
