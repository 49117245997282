import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultipleSelectSearch = _resolveComponent("MultipleSelectSearch")!

  return (_openBlock(), _createBlock(_component_MultipleSelectSearch, {
    options: _ctx.cityData,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    keyName: _ctx.keyName,
    keyValue: _ctx.keyValue,
    isLoading: _ctx.loading,
    placeholder: _ctx.placeholder,
    onFilter: _ctx.fetchCity,
    onFocus: _ctx.fetchCity,
    onBlur: _ctx.onBlur,
    isDisabled: _ctx.disabled,
    disableBackground: _ctx.disabled
  }, null, 8, ["options", "modelValue", "keyName", "keyValue", "isLoading", "placeholder", "onFilter", "onFocus", "onBlur", "isDisabled", "disableBackground"]))
}